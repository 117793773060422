<template>
  <div class="bg-gray-900 relative">
    <!-- Background image and overlay -->
    <div class="absolute inset-0 overflow-hidden">
      <img
        src="@/assets/Dinner.jpeg"
        alt=""
        class="w-full h-full object-center object-cover"
      />
      <div class="absolute inset-0 bg-black opacity-75" />
    </div>

    <div class="relative max-w-7xl mx-auto py-10 px-4 sm:px-6 lg:px-8">
      <div class="text-center">
        <h2
          class="text-3xl font-extrabold tracking-tight text-white sm:text-4xl"
        >
          <span class="block">Ready to dine with us?</span>
        </h2>
        <p class="mt-4 text-lg leading-6 text-gray-200">
          Book your table now at One Squared Bayside.
        </p>
        <div class="mt-8 flex justify-center">
          <div class="bg-white rounded-xl shadow-md">
            <Button />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Button from "@/components/opentable/Button.vue";

export default {
  name: "CTA",
  components: {
    Button,
  },
};
</script>
