<template>
  <div>
    <!-- Header -->
    <div class="relative bg-gray-900 h-screen">
      <!-- Decorative image and overlay -->
      <div
        aria-hidden="true"
        class="absolute inset-0 overflow-hidden shadow-md"
      >
        <img
          :src="menu.Header_Image"
          alt=""
          class="w-full h-full object-center object-cover"
          data-aos="fade-down"
          data-aos-easing="linear"
          data-aos-duration="1500"
        />
        <div aria-hidden="true" class="absolute inset-0 bg-black opacity-60" />
      </div>

      <div
        class="relative max-w-3xl mx-auto py-40 px-6 flex flex-col items-center text-center sm:py-30 sm:py-40 lg:px-0"
      >
        <div class="relative px-4 py-16 sm:px-6 sm:py-24 lg:py-32 lg:px-8">
          <img class="h-10 mx-auto" src="@/assets/5.png" alt="" />
          <h1
            class="mt-5 mb-5 text-center text-4xl font-extrabold tracking-tight sm:text-5xl lg:text-6xl"
          >
            <span class="block text-white">Menu.</span>
          </h1>
          <button
            @click="openMenuInNewWindow"
            class="rl flex items-center justify-center px-4 py-3 border-2 border-sg text-base font-medium text-white hover:bg-pb hover:bg-opacity-50"
          >
            Open Menu in Full Screen
          </button>
        </div>
      </div>
    </div>
    <!-- Header -->

    <!-- PDF Menu -->
    <div class="bg-white">
      <div class="mx-auto -mt-4">
        <div class="flex justify-center mb-4"></div>
        <object
          data="/OneSquaredMenu2025.pdf"
          type="application/pdf"
          class="w-full min-h-screen"
        >
          <p>
            Unable to display PDF file.
            <a href="/OneSquaredMenu2025.pdf">Download</a> instead.
          </p>
        </object>
      </div>
    </div>
    <CTA />
  </div>
</template>

<script>
import axios from "axios";
import CTA from "@/components/CTA.vue";

export default {
  components: {
    CTA,
  },
  data() {
    return {
      menu: {
        Header_Image: "",
      },
    };
  },

  methods: {
    getMenuPage() {
      let __this = this;
      axios
        .get(
          "https://api.storyblok.com/v2/cdn/stories/menu?cv=1657612129&token=e90vmTfGJpbIf0qqX2mihwtt&version=published"
        )
        .then((response) => {
          let content = response.data["story"]["content"];
          __this.menu = {
            Header_Image: content["Header_Image"]["filename"],
          };
        })
        .catch((error) => {
          window.console.log(error);
        });
    },
    openMenuInNewWindow() {
      window.open("/OneSquaredMenu2025.pdf", "_blank", "fullscreen=yes");
    },
  },
  created() {
    this.getMenuPage();
  },
};
</script>

<style></style>
